import { ScrollTo, Section } from "@/components";
import { theme } from "@/styles";
import styled from "@emotion/styled";
import { map } from "lodash";
import React, { useEffect, useRef } from "react";
import UIkit from "uikit";

const UnorderedList = styled.ul`
  & > li.uk-active > a {
    color: ${theme.colors.pink};
  }
`;

const AreasOfServices = ({ data }) => {
  const list = useRef();

  useEffect(() => {
    const switcher = UIkit.switcher(list.current, {
      active: 1,
      connect: "#component-nav",
      animation: "uk-animation-fade",
    });
    return () => switcher.$destroy();
  }, []);

  return (
    <Section>
      <div className="uk-container uk-container-small">
        <h3 className="uk-text-center">Our areas of services.</h3>
        <div className="uk-width-2-3@s uk-align-center">
          <div className="uk-grid uk-grid-collapse" data-uk-grid>
            <div className="uk-width-1-3@m">
              <UnorderedList
                ref={list}
                className="uk-nav uk-nav-primary uk-text-bold uk-text-right@m uk-text-center"
              >
                {map(data, ({ service_name }) => (
                  <li key={service_name}>
                    <a href={`#${service_name}`}>{service_name}</a>
                  </li>
                ))}
              </UnorderedList>
            </div>
            <div className="uk-width-expand@m uk-padding uk-padding-remove-vertical spacing-fix">
              <ul id="component-nav" className="uk-switcher">
                {map(data, ({ service_name, service_description }) => (
                  <li key={service_name}>{service_description}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <ScrollTo href="#our-process" />
        <br />
      </div>
    </Section>
  );
};

export default AreasOfServices;

AreasOfServices.defaultProps = {
  data: [
    {
      service_name: "Business",
      service_description:
        "Our business teams specialises in digital marketing, strategy, and operations optimisation. We aim to increase your marketing reach and help your business grow to heights. From business strategy to implementation, Momentium can provide an end-to-end solution.",
    },
    {
      service_name: "Design",
      service_description:
        "Our design teams specialises in branding, content creation, and digital design. We ensure consistent and effective branding with your company’s name across multiple platforms and channels when scaling your business, or when first developing your organisation’s image in your desired market.",
    },
    {
      service_name: "Technology",
      service_description:
        "Our technology teams specialise in web, mobile application, and enterprise software development. We provide affordable unique solutions that uplift your businesses technology capabilities. We aim to remove the technical barriers that hinder growth.",
    },
  ],
};
