import { Blob, BoostYourBusiness } from "@/components/sections";
import {
  OurAreasOfServices,
  OurProcess,
} from "@/components/sections/WhatWeDoPage";
import React from "react";

const WhatWeDoPage = () => (
  <>
    <OurAreasOfServices />
    <OurProcess />
    <BoostYourBusiness />
    <Blob />
  </>
);

export default WhatWeDoPage;
