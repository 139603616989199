import { Section } from "@/components";
import { theme } from "@/styles";
import styled from "@emotion/styled";
import { map } from "lodash";
import React from "react";

import yellowMan from "./yellowMan.png";

const ContentContainer = styled.div`
  height: 150px;
  @media only screen and (min-width: 768px) {
    height: 300px;
  }
`;

const NumberWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  @media only screen and (min-width: 768px) {
    height: 60px;
  }
`;

const NumberText = styled.div`
  color: ${theme.colors.pink};
  opacity: 0.6;
  user-select: none;
`;

const Content = ({ number, name, description }) => (
  <ContentContainer className="uk-position-relative">
    <NumberWrapper className="">
      <NumberText className="uk-heading-xlarge uk-margin-remove">
        {number}
      </NumberText>
    </NumberWrapper>
    <div className="uk-position-absolute">
      <h4>{name}</h4>
      <p>{description}</p>
    </div>
  </ContentContainer>
);

const OurProcess = ({ data }) => (
  <Section id="our-process">
    <div
      style={{
        width: "100vw",
        height: "400px",
        marginTop: "-150px",
        zIndex: -1,
        position: "relative",
        backgroundImage: "url(" + yellowMan + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "top center",
        maxWidth: "100%",
      }}
      data-uk-parallax="bgy: 20, -70"
    ></div>
    <div className="uk-container uk-container-small">
      <h2 className="uk-text-center">Our Process</h2>

      <div
        className="uk-grid uk-child-width-1-3@s our-process-div"
        data-uk-grid
      >
        {map(data, ({ step, process_name, process_description }) => (
          <Content
            key={step}
            number={step}
            name={process_name}
            description={process_description}
          />
        ))}
      </div>
    </div>
  </Section>
);

export default OurProcess;

OurProcess.defaultProps = {
  data: [
    {
      step: "1",
      process_name: "Coffee Catch-Up",
      process_description:
        "Let’s have a chat about your business so that we understand what you need. We’ll provide a free initial consultation to see what solutions could fit your business requirements.",
    },
    {
      step: "2",
      process_name: "Project Scoping",
      process_description:
        "Our team of experts will take a look at what your requirements and synthesise a solution. We’ll develop a statement of work with the solution and quote for you to review.",
    },
    {
      step: "3",
      process_name: "Project Delivery",
      process_description:
        "Once the statement of work is signed, we’ll assign a project manager and dedicated team that will implement the project deliverables & outcomes.",
    },
  ],
};
